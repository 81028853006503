<template>
	<div id="app">
		<div class="main-wrapper" id="overview">
			<div class="container">
				<div class="row mt-4 mt-lg-5">
					<div id="head" class="col-12 text-center text-lg-left mb-4">
						<p class="text-center"><img src="images/hugh-x2.webp" width="218" alt="Hugh"></p>
						<h1 class="text-center"><img src="images/Cchange-logo-x2.webp" width="238" alt="c-change logo"></h1>
						<p class="text-center text-white" style="max-width: 900px;margin: auto;">Need a laugh or a joke this holiday season? We’ve got you covered with our Joke Generator. At c|change, we love a silly dad joke, just something to make us chuckle, and we’re happy to share some of our favorites with you. Keep clicking the button below for a rolling stream of hilarious jokes. Enjoy!</p>
					</div>
					<div id="body" class="col-12 ov-content">
						<h2 id="joke-hl" class="text-center">Joke Generator</h2>
						<div id="jokes">
							<p id="thequestion" class="h_av text-left" :class="{show : showquestion}"><span>{{results.question}}</span></p>
							<p id="tellme" class="p_av text-right" :class="{show : showanswer}"><span>{{results.tellme}}</span></p>
							<p id="theanswer" class="h_av text-left" :class="{show : showanswer}"><span>{{results.answer}}</span></p>
						</div>
						<div id="buttons">
							<button id="showanswer" class="text-center" :class="{show : !showanswer}" v-on:click="showAnswer()"><span>{{results.tellme}}</span></button>
							<button id="newjoke" class="text-center " :class="{show : showanswer}" v-on:click="newJoke()"><span>New<br>joke</span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
  import jokes from './jokes.json'

  export default {
    props: ['question', 'answer'],
    data() {
      return {
        jokes : jokes,
        min: 0,
        max: 119,
        number: 1,
        results:'',
        showquestion: true,
        showanswer: false,
        showall: true
      }
    },
    created: function () {
      this.getRandomNumber()
    },
    methods : {
      showAll(){
        this.showall = !this.showall
      },
      newJoke(){
        this.showquestion = false
        this.number = this.generateNumber()
        this.results = this.jokes[this.number]
        this.showanswer = !this.showanswer
        setTimeout(() => this.showquestion = true, 500);
      },
      showAnswer: function () {
        this.showanswer = !this.showanswer
      },
      getRandomNumber: function () {
        this.number = this.generateNumber();
        this.results = this.jokes[this.number];
      },
      generateNumber: function () {
        return Math.floor(Math.random()*(this.max-this.min+1)+this.min);
      }
    },
    computed: {
      groupedJokes: function() {
        return this.jokes
      }
    }
  }
</script>
<style lang="scss">
  @import './assets/scss/variables.scss';
  @import './assets/scss/basics.scss';
  @import './assets/scss/nav.scss';
  @import './assets/scss/main.scss';
</style>